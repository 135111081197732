import { IconNuevaVentana } from 'icons/components/IconNuevaVentana'
import { cx } from 'utils/cx'

import { BUTTON_VARIANT } from '../Button.types'
import { ButtonBase } from '../ButtonBase'

import styles from './ButtonContentLink.module.scss'

export const enum LINK_SIZE {
	S = 'small',
	M = 'medium',
}

export interface ContentLinkProps extends React.ComponentProps<'button'> {
	openInNewTab?: boolean
	size?: LINK_SIZE
	variant?: BUTTON_VARIANT
}

export function ButtonContentLink(props: ContentLinkProps): React.ReactNode {
	const {
		children,
		openInNewTab,
		variant = BUTTON_VARIANT.DEFAULT,
		size = LINK_SIZE.M,
		className,
		...rest
	} = props

	return (
		<ButtonBase
			{...rest}
			className={cx(className, styles[variant], styles[size])}
		>
			{children}
			{openInNewTab && (
				<IconNuevaVentana lang='en' alt='Opens in a new window' />
			)}
		</ButtonBase>
	)
}
