'use client'

import { useCookiesCompliance } from 'cookies/hooks/useCookiesCompliance'
import { useCookiesContext } from 'cookies/hooks/useCookiesContext'
import { ButtonType } from 'cookies/types'
import { ButtonContentLink } from 'fukku/Button/ContentLink'
import { ClientLabel } from 'labels/client'
import { useLabels } from 'labels/hooks/useLabels/useLabels'

import { CookiesFooter } from './CookiesFooter/CookiesFooter'

import styles from './CookiesNotice.module.scss'
import text from 'fukku/styles/texts.module.scss'

export const CookiesNotice = () => {
	const { t } = useLabels()
	const { buttonsConfiguration } = useCookiesContext()
	const { rejectAllCookies } = useCookiesCompliance()
	const { rejectAll } = buttonsConfiguration

	const placeholderValues = {
		linkHere: `/privacy-policy#cookies`, // TODO delete after publishing key in all env
		PrivacyPolicyCookies: `/privacy-policy#cookies`,
	}

	return (
		<div className={styles.cookiesNotice}>
			<div className={styles.cookiesNoticeContent}>
				{rejectAll.firstLevel && rejectAll.type === ButtonType.Link && (
					<ButtonContentLink
						className={styles.rejectFirstLevel}
						onClick={rejectAllCookies}
						data-testid='cookies.button.rejectAll'
					>
						{t('cookies.configuration.continueWithoutAccept.link')}
					</ButtonContentLink>
				)}
				<div className={styles.cookiesTitle}>
					<h2 className={text.titleL}>{t('cookiesBanner.title')}</h2>
				</div>
				<div className={styles.cookiesPolicy}>
					<ClientLabel
						placeholderValues={placeholderValues}
						linkProps={{ className: styles.link }}
					>
						{t('cookies.banner.bodycopy')}
					</ClientLabel>
				</div>

				<CookiesFooter />
			</div>
		</div>
	)
}
